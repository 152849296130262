"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getEmissionModelIds = void 0;
var getEmissionModelIds = exports.getEmissionModelIds = function getEmissionModelIds(modelGroups) {
  return modelGroups.flatMap(function (mg) {
    return mg.variants.filter(function (v) {
      return v.emissionModelId;
    }).map(function (v) {
      return v.emissionModelId;
    });
  });
};