"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.shouldShowLamborghiniCE = void 0;
var shouldShowLamborghiniCE = exports.shouldShowLamborghiniCE = function shouldShowLamborghiniCE(_ref) {
  var vehicle = _ref.vehicle;
  var registration = vehicle.registration,
    odometer = vehicle.odometer;
  var odometerLimit = (odometer === null || odometer === void 0 ? void 0 : odometer.unit) === 'mi' ? 1242 : 2000;
  var isCEApplicable = registration >= 2018;
  var isNew = (odometer === null || odometer === void 0 ? void 0 : odometer.reading) < odometerLimit;
  return isCEApplicable && isNew;
};